import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import qs from "qs";
import { graphql, PageProps, withPrefix } from "gatsby";
import { orderBy } from "lodash";
import Helmet from "react-helmet";
import {
  ClearRefinements,
  Configure,
  connectStats,
  InfiniteHits,
  InstantSearch,
  Panel,
  RefinementList,
  SearchBox
} from "react-instantsearch-dom";
import * as hitComps from "../../components/HitComp";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SEO from "../../components/Seo";
import StructuredData from "../../components/StructuredData";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import BackToTop from "../../components/BackToTop";
import { ImageInterface, LocalizedContextInterface, PortableText, Seo } from "../../types/SanityTypes";
import "./styles.scss";
import ProductRatingsAndReviewsScript from "../../components/ProductRatingsAndReviewsScript";
import { SearchState } from "react-instantsearch-core";
import { LocalisedPageContext } from "../../types/PageTypes";
import { HitCompInterface } from "../../components/HitComp";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import { useAlgoliaClient } from "../../utils/hooks";
import { event34 } from "../../analytics/event34";
import { event35 } from "../../analytics/event35";

type SearchPageProps = {
  search: {
    _id: string;
    seo: Seo;
    slug: {
      current: string;
    };
    placeholder: {
      asset: {
        url: string;
      }
    }
    heading: string;
    _rawPlaceholder: ImageInterface;
    _rawSuccessfulSearch: PortableText;
    _rawUnsuccessfulSearch: PortableText;
  };
} & LocalizedContextInterface;

type SearchPageContext = LocalisedPageContext;

type RefinementListItem = {
  count: number;
  isRefined: boolean;
  label: string;
  value: Array<string>;
};

export const query = graphql`
  query SearchPage($_id: String, $language: String, $market: String) {
    search: sanitySearch(_id: { eq: $_id }) {
      ...SearchPageFieldsFull
    }
    ...LocalizedContext
  }
`;

const Search = (props: PageProps<SearchPageProps, SearchPageContext>) => {
  const language = props.pageContext.language;
  const searchPage = props.data.search;
  const searchLabels = props.data.sanityLabels?.searchLabels;
  const imageUrl = props.data?.search?.placeholder?.asset?.url;

  const { algoliaIndex, siteUrl } = useSiteMetadata();
  const algoliaClient = useAlgoliaClient();

  const localizedPath = withPrefix(getLocalizedPath(searchPage.slug.current, searchPage._id));

  const DEBOUNCE_TIME = 400;

  const createURL = (searchState: SearchState) => {
    return `?${qs.stringify(searchState)}`;
  };

  const searchStateToUrl = (location: Location, searchState: SearchState) => {
    return searchState ? `${location.pathname}${createURL(searchState)}` : "";
  };

  const urlToSearchState = (search: string) => {
    return qs.parse(search.slice(1));
  };

  const [searchState, setSearchState] = useState<SearchState>(() => {
    return typeof window !== `undefined` ? urlToSearchState(location.search) : {};
  });

  const [debouncedSetState, setDebouncedSetState] = useState<number>();

  const onSearchStateChange = (updatedSearchState: SearchState) => {
    clearTimeout(debouncedSetState);

    setDebouncedSetState(
      window.setTimeout(() => {
        return history.pushState(updatedSearchState, "", searchStateToUrl(location, updatedSearchState));
      }, DEBOUNCE_TIME)
    );

    setSearchState(updatedSearchState);
  };

  const [resultHits, setResultHits] = useState(0);
  const previousValues = useRef(searchState.query);

  useEffect(() => {
    const hasResults = resultHits !== 0;
    if (previousValues.current !== searchState.query) {
      previousValues.current = searchState.query;
      hasResults ? event34(searchState.query, resultHits) : event35(searchState.query);
    }
  }, [resultHits]);

  const Hits: FunctionComponent<{ nbHits: number; query: string }> = ({ nbHits, query }) => {
    const hasResults = nbHits !== 0;
    setResultHits(nbHits);

    return (
      <>
        <div hidden={!hasResults}>
          <BlockContent blocks={searchPage._rawSuccessfulSearch} /> <p>{`${nbHits}`}</p>
        </div>
        <div hidden={hasResults}>
          <BlockContent blocks={searchPage._rawUnsuccessfulSearch} />
        </div>
      </>
    );
  };

  const CustomStats = connectStats(Hits);

  const [filterView, setFilterView] = useState("hide-on-mobile");
  const [displaySign, setDisplaySign] = useState("+");

  const toggleFilterView = () => {
    setFilterView(filterView === "hide-on-mobile" ? "" : "hide-on-mobile");
    setDisplaySign(displaySign === "-" ? "+" : "-");
  };

  const hitCompData: HitCompInterface = {
    placeholderImage: searchPage._rawPlaceholder,
    productsLandingPageHeading: props.data.sanityProductLandingPage?.heading,
    productsLandingPageSlug: props.data.sanityProductLandingPage?.slug.current,
    article: searchLabels?.article
  };

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={searchPage.seo.metaTitle}
      pageType={"search"}
      contentType={"Search"}
    >
      <Helmet>
        <link rel="canonical" href={`${siteUrl}${localizedPath}`} />
      </Helmet>
      <ProductRatingsAndReviewsScript /> {/*For product ratings shown on HitComp*/}
      <StructuredData customData={searchPage.seo.jsonld} />
      <SEO title={searchPage.seo.metaTitle} description={searchPage.seo.metaDescription} imageUrl={imageUrl} />
      <InstantSearch
        searchClient={algoliaClient}
        indexName={algoliaIndex}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <Configure filters={`lang:${language}`} />
        <div className="page_search">
          <Container fluid>
            <div className="content" id="search-box">
              <h1>{searchPage.heading} </h1>
              <SearchBox
                searchAsYouType={false}
                translations={{ placeholder: "" }}
                autoFocus={true}
                aria-label="Search"
              />
            </div>
            {searchState.query && (
              <div className="content">
                <span className="stats">
                  <CustomStats query={searchState.query} />
                </span>
              </div>
            )}
            {resultHits !== 0 ? (
              <Row>
                <Col sm={12} lg={3} className="filters">
                  <div className="filters-content">
                    <div onClick={toggleFilterView}>
                      <span>
                        {searchLabels?.filter}
                        <div className="hide-on-desktop">{displaySign}</div>
                      </span>
                    </div>
                    <ClearRefinements
                      clearsQuery
                      translations={{
                        reset: `${searchLabels?.reset}`
                      }}
                    />
                  </div>
                  <div className={"filter-wrapper " + filterView}>
                    <div className="filter">
                      <Panel header={searchLabels?.type}>
                        <RefinementList
                          className="options"
                          attribute="pageType"
                          limit={4}
                          showMoreLimit={4}
                          showMore={false}
                          transformItems={(items: Array<RefinementListItem>) => {
                            items = items.map(item => ({
                              ...item,
                              // @ts-ignore
                              label: searchLabels[item.label] || item.label
                            }));
                            return orderBy(items, "label", "asc");
                          }}
                        />
                      </Panel>
                    </div>
                    <div className="filter">
                      <Panel header={searchLabels?.topics}>
                        <RefinementList
                          className="options"
                          attribute="tags.name"
                          limit={4}
                          showMoreLimit={50}
                          showMore={true}
                          translations={{
                            showMore(expanded) {
                              return expanded ? `${searchLabels?.showLess}` : `${searchLabels?.showMore}`;
                            }
                          }}
                          transformItems={(items: Array<unknown>) => orderBy(items, "label", "asc")}
                        />
                      </Panel>
                    </div>
                  </div>
                </Col>
                <Col sm={12} lg={{ span: 7, offset: 1 }} xl={{ span: 6, offset: 3 }}>
                  <div className="wrapper">
                    <InfiniteHits
                      showPrevious={false}
                      hitComponent={hitComps["HitComp"](hitCompData)}
                      translations={{
                        loadMore: `${searchLabels?.loadMore}`
                      }}
                    />
                  </div>
                </Col>
              </Row>
            ) : (
              <div className="no-results" />
            )}
          </Container>
          {resultHits >= 3 ? (
            <div className="back-to-top-display">
              <BackToTop degree={0} startColor={"var(--blue-color)"} endColor={"var(--blue-color)"} />
            </div>
          ) : null}
        </div>
      </InstantSearch>
    </Layout>
  );
};

export default Search;
