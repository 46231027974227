import React from "react";
import { Link } from "../Link";
import { Highlight } from "react-instantsearch-dom";
import { useInView } from "react-intersection-observer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProductRatings from "../ProductRatings";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface } from "../../types/SanityTypes";
import "./styles.scss";
import { Hit } from "../../types/SearchTypes";
import normalize from "normalize-path";

export interface HitCompInterface {
  placeholderImage?: ImageInterface;
  productsLandingPageHeading?: string;
  productsLandingPageSlug?: string;
  article?: string;
}

export const HitComp = ({
  placeholderImage,
  productsLandingPageHeading,
  productsLandingPageSlug,
  article
  // eslint-disable-next-line react/display-name
}: HitCompInterface) => ({ hit }: { hit: Hit }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { sanityId, sanityDataset } = useSiteMetadata();

  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "5px 0px"
  });
  const { slug, title, objectID, image, pageType, parentSection, upc } = hit;
  const getImage = () => {
    return image ? image : placeholderImage;
  };
  const imageAlt = () => {
    return image ? image.alt : "placeholder";
  };

  const renderPath = () => {
    return pageType === "article" && parentSection ? (
      <p>
        {parentSection.name} / {article}
      </p>
    ) : (
      <p>{productsLandingPageHeading}</p>
    );
  };

  const parentPath = parentSection?.parentSection
    ? `${parentSection.parentSection.slug.current}/${parentSection.slug.current}`
    : parentSection?.slug?.current;

  const renderSlug = () => {
    const urlPath =
      pageType === "article" && parentSection
        ? `/${parentPath}/${slug.current}/`
        : `/${productsLandingPageSlug}/${slug.current}/`;
    return normalize(urlPath, false);
  };

  const renderImage = () => {
    return pageType === "article" ? (
      <>
        <source
          media={"(min-width: 992px)"}
          srcSet={
            urlBuilder
              .image(getImage())
              .auto("format")
              .quality(80)
              .width(210)
              .height(190)
              .format("webp")
              .url() as string
          }
        />
        <source
          media={"(min-width: 768px)"}
          srcSet={
            urlBuilder
              .image(getImage())
              .auto("format")
              .quality(80)
              .width(190)
              .height(170)
              .format("webp")
              .url() as string
          }
        />
        <img
          src={
            urlBuilder
              .image(getImage())
              .auto("format")
              .quality(80)
              .width(150)
              .height(130)
              .format("webp")
              .url() as string
          }
          alt={imageAlt()}
          style={{ padding: 0 }}
        />
      </>
    ) : (
      <>
        <source
          media={"(min-width: 992px)"}
          srcSet={
            urlBuilder
              .image(getImage())
              .auto("format")
              .quality(80)
              .width(180)
              .height(180)
              .format("webp")
              .url() as string
          }
        />
        <source
          media={"(min-width: 768px)"}
          srcSet={
            urlBuilder
              .image(getImage())
              .auto("format")
              .quality(80)
              .width(160)
              .height(160)
              .format("webp")
              .url() as string
          }
        />
        <img
          src={
            urlBuilder
              .image(getImage())
              .auto("format")
              .quality(80)
              .width(120)
              .height(120)
              .format("webp")
              .url() as string
          }
          alt={imageAlt()}
        />
      </>
    );
  };

  return (
    <div ref={ref} data-inview={inView} className="hit-comp">
      <Link _id={objectID} className="ais-InfiniteHits-item__link" to={renderSlug()} aria-label={title}>
        <Row>
          <Col xs={{ span: 7, order: "first" }} lg={{ span: 8, order: "last" }} className="ais-InfiniteHits-item__copy">
            <h4>
              <Highlight attribute="title" hit={hit} tagName="mark" />
            </h4>
            {pageType === "product" && <ProductRatings upc={objectID} productName={""} template={""} />}
            {(pageType === "productV2" && upc) && <ProductRatings upc={upc} productName={""} template={""} />}
            {renderPath()}
          </Col>
          <Col
            xs={{ span: 4, order: "last" }}
            sm={{ offset: 1 }}
            lg={{ span: 4, order: "first", offset: 0 }}
            className="image-wrapper"
          >
            <figure>{inView ? renderImage() : null}</figure>
          </Col>
        </Row>
      </Link>
    </div>
  );
};