import { useState, useEffect } from "react";
import algoliasearch from "algoliasearch/lite";
import { useSiteMetadata } from "../hooks/useSiteMetadata";

export const useIntersect = (RefElement: any, threshold: number[] = [0, 1], rootMargin = "-1px 0px 0px 0px") => {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const cachedRef = RefElement.current,
            observer = new IntersectionObserver(([e]) => setIsSticky(e.intersectionRatio === 0), {
                threshold,
                rootMargin
            });

        cachedRef && observer.observe(cachedRef);

        // unmount
        return function () {
            cachedRef && observer.unobserve(cachedRef);
        };
    }, [RefElement]);

    return isSticky;
};

export const useAlgoliaClient = () => {
    const { algoliaAppId, algoliaSearchApiKey } = useSiteMetadata();
    const algoliaClient = algoliasearch(algoliaAppId, algoliaSearchApiKey, {
        timeouts: {
            connect: 5,
            read: 10,
            write: 50
        }
    });
    return algoliaClient;
}